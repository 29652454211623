/**
* Group Detail
* ============================================================================
*/

.group-detail {
  position: relative;
  clear: both;

  h3 {
    font-size: 22px;
    margin: 0 0 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    em {
      font-style: normal;
      font-weight: 400;
      color: @70;
      font-size: 90%;
    }
  }

  .count {
    &.short-id-box {
      min-width: 0;

      span:not(.help-link) {
        font-size: 18px;
        line-height: 1.45;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        direction: rtl;
      }
    }

    span:not(.help-link) {
      font-size: 22px;
      display: inline-block;
      line-height: 1.2;
    }
  }

  .assigned-to {
    text-align: right;
    position: relative;
  }

  .meta {
    margin: 0 0 15px;
    color: #949ea4;
  }

  .id-label {
    background: #fff;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .severity {
    display: inline-block;
    position: relative;
    top: -7px;
    padding: 0 10px;
  }

  .nav-tabs {
    margin-top: 20px;
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    display: block;
    content: '';
    bottom: -1px;
    left: -40px;
    right: -40px;
    height: 1px;
    background: @trim;
  }
}

/**
* Event Details
* ============================================================================
*/

.event-details-container {
  display: flex;
  margin: -20px -40px;
  background: #fff;
  flex-direction: column;
  flex: 1;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  .primary {
    flex: 1;

    .user-report {
      border-radius: 0;
      box-shadow: none;
      padding: 20px 30px 0 40px;
      border: 0;
      border-top: 1px solid lighten(@trim, 4);
      margin: 0;

      padding-bottom: 0;
    }

    @media (min-width: 1200px) {
      // TODO(dcramer): whatever flex is doing here is causing this to extend to
      // whatever it pleases (e.g. 4k pixels on my 1280 screen)
      max-width: ~'calc(100% - 365px)';
    }
  }

  .secondary {
    width: 100%;
    padding: 30px 40px 30px 30px;
    border-left: 1px solid @trim;

    @media (min-width: 1200px) {
      width: 365px;
    }
  }
}

.module-user .avatar {
  width: 64px;
  height: 64px;

  img {
    height: 100%;
    width: 100%;
  }
}

.event-stats {
  padding-top: 84px;
  margin-top: -84px;
}

.event-stats {
  h6 {
    font-size: 14px;
    margin-bottom: 15px;
    position: relative;

    > span {
      background: @white-dark;
      padding-right: 7px;
    }

    &:before {
      display: block;
      position: absolute;
      content: '';
      border-top: 1px solid lighten(@trim, 9);
      left: 0;
      right: 0;
      top: 50%;
      z-index: -1;
    }
  }
}

.user-widget {
  position: relative;
  margin-bottom: 20px;

  .avatar {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0 0 0 5px #fff;
    background: #fff;
    border-radius: 50% 0 50% 50%;
  }

  .table {
    margin-bottom: 0;
  }
}

.ai-loader {
  height: 250px;
  background: center / contain no-repeat url(../images/spot/ai-loader.gif);
  margin-top: -20px;
  margin-bottom: -35px;
}

/**
* Traceback
* ============================================================================
*/

.traceback {
  list-style-type: none;
  padding-left: 0;
  border: 1px solid @trim-dark;
  border-radius: 0 6px 6px 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}

// TODO(dcramer): we probably shouldn't overload these
pre.traceback {
  margin: 0 0 20px;
}

div.traceback > ul {
  padding: 0;
  margin-bottom: 0;

  li {
    &:first-child {
      border-top: none;

      .title {
        border-top: none;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }

    &:last-child .title {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}

.raw-toggle {
  input {
    margin-right: 6px;
    position: relative;
    top: -1px;
  }
}

/**
* Frame
* ============================================================================
*/

.exc-message {
  margin: 15px 0 0;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: @gray-darker;
  padding: 0;
  background: none;
}

.frames-omitted,
.exc-omitted {
  color: @gray-dark;
  font-size: 14px;
  font-weight: 600;
  border-left: 3px solid @red;
  border-right: 1px solid lighten(@red, 30%);
  background: lighten(@red, 35%);
  padding: 10px 20px;
  margin-bottom: 10px !important;
  margin-left: -21px;
  margin-right: -21px;
}

.exception {
  margin: 0 0 20px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  h5 {
    line-height: 1.2;
  }

  .traceback {
    margin-top: 15px;
  }
}

.traceback {
  &.no-exception .frame:first-child {
    border-top: 0;
  }

  .frame {
    list-style-type: none;
    position: relative;
    margin: 0;
    border-top: 1px solid @trim;

    h3 {
      font-size: 22px;
    }

    &.is-expandable .title {
      cursor: pointer;
    }

    .title {
      padding: 6px 20px;
      font-size: 13px;
      margin: 0;
      line-height: 16px;
      background: #faf9fb;

      word-break: break-all;
      /* for firefox */
      word-break: break-word;
      /* for chrome */

      code {
        font-family: inherit;
      }

      .informal {
        font-style: italic;
      }
    }

    .title.as-table {
      width: 100%;
      padding-left: 15px;

      .package {
        font-size: 13px;
        font-weight: bold;
        .truncate;
        flex-basis: 120px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .address {
        font-family: @font-family-code;
        font-size: 11px;
        color: @gray-dark;
        letter-spacing: -0.25px;
        width: 100px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .symbol {
        word-break: break-word;
        flex: 1;

        code {
          background: transparent;
          color: @blue-dark;
          padding-right: 5px;

          a {
            color: inherit;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        span.offset {
          font-weight: bold;
          padding-right: 10px;
        }

        span.filename {
          color: @purple;
          margin-right: 3px;

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }
      }
    }

    .original-src {
      font-size: 12px;
      padding-left: 3px;
      position: relative;
      top: 1px;
    }

    .icon-open {
      font-size: 12px;
      margin-right: 3px;
      margin-left: 3px;
      position: relative;
      top: 1px;
    }

    .in-at {
      opacity: 0.6;
      margin: 0 2px;
    }

    .blame {
      color: lighten(@gray, 5);

      a {
        color: @gray;
      }

      .icon-mark-github {
        position: relative;
        top: 1px;
      }
    }

    .tooltip-inner {
      word-wrap: break-word;
      text-align: left;
      max-width: 300px;
    }

    .divider {
      border-left: 1px solid @trim;
      display: inline-block;
      width: 1px;
      height: 10px;
      margin: 0 6px;
      position: relative;
      top: 1px;
    }

    code {
      padding: 0;
      background: inherit;
      font-size: inherit;
      color: inherit;
    }

    .context {
      display: none;
      background: #fff;
      margin: 0;

      table.key-value {
        border-top: 1px solid @trim;
        padding: 0;
        margin: 0;

        td {
          border-bottom: 1px solid @trim !important;

          &.key {
            width: 145px;
            max-width: 145px;
            padding-left: 20px !important;
          }

          &.value pre {
            background: inherit;
          }
        }

        tr:last-child {
          td {
            border-bottom: 0 !important;
          }
        }
      }

      &.expanded {
        display: block;
      }
    }

    .tag-app {
      color: #aaa;
      font-size: 0.9em;
      margin-left: 10px;
    }

    > div > table.key-value {
      margin-bottom: 5px;

      > tbody > tr > th {
        color: @gray-dark;
        text-align: right;
        padding-right: 12px !important;
      }
    }

    .btn-toggle {
      display: block;
      float: right;
      width: 16px;
      height: 16px;
      padding: 0;
      line-height: 16px;
      font-size: 9px;
      text-align: center;
      flex-shrink: 0;
    }

    .expand-button:hover {
      cursor: pointer;
    }

    &.expanded {
      .expandable {
        height: auto;
        max-width: 100%;
      }
    }

    &:last-child {
      .context {
        margin-bottom: 0;
      }

      .toggle-expand .btn {
        margin-bottom: -13px;
      }
    }
  }

  &.in-app-traceback .frame.leads-to-app {
    .leads-to-app-hint {
      display: none;
    }

    &.collapsed {
      .title {
        padding: 4px 20px 4px 20px;
        background: #faf9fb;
        min-height: 32px;

        &.is-expandable:hover {
          opacity: 1;
        }

        .package {
          width: auto;
        }
      }

      .title.as-table {
        padding-left: 15px;
      }

      .in-at-line,
      .lineno,
      .address {
        display: none;
      }

      .leads-to-app-hint {
        display: inline;
        color: @gray-light;
      }
    }
  }

  &.full-traceback {
    .leads-to-app-hint {
      display: none !important;
    }
  }

  .expandable {
    height: 0;
    position: relative;

    .icon-plus {
      position: absolute;
      left: 8px;
      top: 6px;
      opacity: 0.25;
      transition: 0.1s opacity linear;
    }

    &.key-value {
      display: none;
    }

    .ws {
      display: none;
      overflow: hidden;
    }

    &:hover {
      .icon-plus {
        opacity: 0.5;
      }
    }
  }

  .expanded {
    .expandable {
      height: auto;
    }

    .ws {
      display: inline;
      overflow: none;
    }
  }

  ol.context {
    margin: 0;
    list-style-position: inside;
    list-style-type: decimal-leading-zero;
    border-radius: 5px;
    padding-left: 0;

    .key-value {
      display: none;

      pre {
        overflow: auto;
      }
    }

    > li {
      font-family: @font-family-code;
      line-height: 24px;
      font-size: 12px;
      white-space: pre;
      white-space: pre-wrap;
      word-wrap: break-word;
      min-height: 24px;
    }

    > li.active {
      list-style-type: none;
      border-radius: 2px;

      &:first-child:last-child {
        background-color: inherit;
        color: inherit;
        border-radius: 0;
      }

      pre {
        color: @gray-dark;
      }
    }

    > li:first-child {
      border-radius: 2px 2px 0 0;
    }

    > li:last-of-type {
      border-radius: 0 0 2px 2px;
    }

    li.closed {
      border-radius: 2px;
    }

    &.expanded {
      .key-value {
        display: table;
      }

      > li.active {
        position: relative;
        z-index: 0;
        list-style-type: inherit;
        border-radius: 0;
      }
    }
  }

  ol.context-line {
    > li {
      > span {
        float: right;
      }
    }
  }
}

#full-message {
  line-height: 1.5em;

  &#full-message {
    background: none;
    padding: 0;
  }
}

&.expanded div.commands,
div.commands:hover {
  opacity: 1;

  a {
    color: @state-info-text;
  }
}

div.commands {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 0.9em;
  opacity: 0.3;

  a {
    color: #666;
    text-decoration: none;

    span {
      font-size: 9px;
      margin-right: 5px;
    }
  }
}

// Request

.request {
  .path {
    font-weight: normal;
    float: left;

    strong {
      margin-right: 4px;
    }
  }
}

/* message details */
.server-list,
.url-list,
.logger-list,
.level-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.server-list li,
.url-list li {
  padding-bottom: 10px;
}

.url-list li a {
  line-height: 18px;
}

pre.val,
span.val {
  span {
    display: inline;
  }
}

.val-string {
  border-radius: 4px;
  padding: 2px 4px;
}

.val-string-multiline {
  display: block;
  overflow: auto;
}

.val-stripped {
  color: darken(@red, 10);
  background: transparent;
}

.val-bool,
.val-null {
  color: darken(@blue, 10);
  font-weight: bold;
}

.val-dict {
  display: inline;

  .val-dict-items {
    display: block;
    padding: 0 0 0 15px;

    .val-dict-pair {
      display: block;
    }
  }

  .val-dict-key,
  .val-dict-key .val-string {
    color: darken(@red, 5);
    background: transparent;
    border-radius: none;
  }
}

.val-array {
  .val-array-items {
    display: block;
    padding: 0 0 0 15px;

    .val-array-item {
      display: block;
    }
  }
}

/**
* Exceptions and Threads
* ============================================================================
*/
.box .thread {
  .exception {
    margin-top: 10px;
  }

  .traceback.no-exception {
    margin-top: 0;
  }
}

/**
* Shared Group Detail
* ============================================================================
*/

.shared-group {
  padding: 40px 0;
  background: none;

  .app > .container {
    max-width: 960px;

    > .box {
      > .box-header {
        padding: 15px 30px 13px;
        display: flex;
        align-items: center;

        a {
          &.logo {
            font-size: 20px;
            flex: 1;
          }

          &:hover {
            color: @gray-dark;
          }
        }

        .back-to,
        .pull-right a {
          font-size: 16px;
        }

        .back-to {
          border-left: 1px solid @trim;
          padding: 2px 10px;
          display: inline-block;
          margin-left: 7px;
          position: relative;
          top: -3px;
        }
      }
    }
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .group-detail {
    padding: 0 30px;
    border-bottom: 1px solid darken(@trim, 5);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);

    &:before {
      display: none;
    }

    .details {
      max-width: 960px;
      margin: 0 auto;
      padding: 20px 0 0;
    }
  }

  .event-details-container {
    display: block;
    margin: 0;

    .primary {
      max-width: 100%;

      .box {
        padding: 0 30px;

        &:first-child {
          border-top: 0;
        }
      }

      .box-header,
      .box-content {
        max-width: 960px;
        margin: 0 auto;
      }

      .box-header {
        margin-top: 15px;
      }

      .exception {
        padding: 20px 0;
        margin: 0;

        &:first-child {
          padding-top: 0;
        }
      }

      .traceback.no-exception {
        margin-bottom: 25px;
        margin-top: -5px;
      }
    }
  }
}

/**
* Breadcrumbs / Timeline
* ============================================================================
*/

ul.crumbs {
  li {
    .table.key-value {
      margin-bottom: 0;
      background: none;

      thead td:first-child {
        font-weight: bold;
      }

      td {
        padding: 2px 0 !important;
        font-weight: normal;
      }

      .key {
        width: 122px;
        max-width: 122px;
        color: @gray-dark;
        line-height: 1.6 !important;
      }

      pre {
        display: inline-block;
        padding: 0;
        margin-top: 1px;
        background: none;
        word-break: break-all;
      }
    }

    div.summary {
      overflow: hidden;

      &.can-expand {
        cursor: pointer;
        position: relative;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          content: ' ';
          display: block;
          width: 100%;
          height: 15px;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
          background-repeat: repeat-x;
          z-index: 1;
        }

        &:hover {
          &:before {
            position: absolute;
            bottom: 4px;
            right: 0;
            font-family: 'sentry-simple';
            content: '\e617';
            background: @white-dark;
            border: 1px solid darken(@trim, 5);
            border-radius: 3px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            z-index: 2;
            padding: 0 5px;
            margin-left: -14px;
          }
        }
      }

      &.expanded {
        max-height: none;

        &:after,
        &:before {
          display: none;
        }
      }

      pre {
        display: inline-block;
      }
    }
  }
}

/**
* Responsive medium screen
* ============================================================================
*/

@media (max-width: 991px) {
  .group-stats-column {
    float: none;
  }
}

/**
* Responsive small screen
* ============================================================================
*/

@media (max-width: 767px) {
  .group-detail {
    h3 {
      white-space: normal;
      font-size: 20px;
      line-height: 1.2;
    }

    .meta {
      font-size: 13px;
    }

    .short-id-box {
      text-align: left;
    }

    .is-assigned {
      padding-top: 0;
    }
  }

  // Event detail columns

  .event-details-container {
    flex-direction: column;
    margin: 0;

    .primary {
      max-width: 100%;

      .box {
        padding: 15px 0;

        .box-header {
          h3 {
            margin: 0 0 10px;

            small {
              margin-left: 0 !important;
              display: block;
            }
          }

          .permalink {
            display: none;
          }
        }
      }

      .user-report {
        padding: 15px 0;
      }

      .exception {
        margin: 0;
        padding: 15px 0;
      }

      .traceback {
        &.no-exception {
          margin: 0;
        }

        .btn-toggle {
          width: 17px;
        }
      }
    }

    .secondary {
      width: auto;
      padding: 0;
      border: 0;
      margin-bottom: 20px;
    }

    .detailed-error {
      border-top: 1px solid @trim;
    }
  }

  // Context callout

  .context-summary {
    flex-direction: column;
    margin-top: 0;
    padding: 0;
    margin-bottom: 20px;
  }

  .context {
    overflow: auto;

    li {
      width: 800px;
    }
  }
}
